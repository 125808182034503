.ios-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; /* Center text and icon horizontally */
    text-align: center;
    white-space: nowrap;
    font-size: 17px;
    line-height: 1.17648;
    font-weight: 400;
    letter-spacing: -.022em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    border-style: solid;
    border-width: 1px;
    min-width: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 980px;
    background: #0071e3;
    color: #fff;
    border-color: rgba(0, 0, 0, 0);
    font-size: 17px;
    line-height: 1.17648;
    font-weight: 400;
    letter-spacing: -.022em;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    border-style: solid;
    border-width: 1px;
    min-width: 28px;
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 11px;
    padding-bottom: 11px;
    --sk-button-margin-horizontal: 18px;
    --sk-button-margin-vertical: 18px;
}

.ios-button svg {
    margin-left: 8px; /* Adjust spacing between text and icon */
}

.ios-button:hover {
    background-color: #005bb5; /* Slightly darker on hover */
}

.ios-button:active {
    transform: scale(0.98); /* Small scale down on click */
}

.ios-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 113, 227, 0.5); /* Focus ring */
}
