/* Import Roboto from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400; /* Normal text weight */
  scroll-behavior: smooth; /* Smooth scrolling behavior */
  background: linear-gradient(
    to bottom,
    #f0f0f5, /* Very light grayish blue */
    #e1e1e6, /* Light grayish blue */
    #d2d2d8, /* Slightly darker grayish blue */
    #c3c3cc, /* Grayish blue */
    #b4b4c0  /* Slightly darker grayish blue nearing white */
  );
  background-size: 100% 500%; /* Adjust based on the height of your sections */
  background-position: top;
  background-repeat: no-repeat;
  
}

/* You can also customize specific font weights */
h1 {
  font-weight: 700; /* Bold headings for emphasis */
}

p {
  font-weight: 400; /* Regular weight for body text */
}

/* Basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

/* Continuous Scroll Container with Scroll Snap */
.continuous-scroll-container {
  width: 100%;
  scroll-snap-type: y mandatory; /* Enables vertical scroll snap */
  overflow-y: scroll; /* Enables vertical scrolling */
  height: 100vh; /* Full viewport height to allow scrolling */
  
}
/* hide scrollbar */
.continuous-scroll-container::-webkit-scrollbar {
  display: none;
}
/* Section Styles with Scroll Snap Alignment */
.section {
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  color: #333; /* Dark gray for text */
  text-align: center;
  transition: background-color 0.5s ease;
  scroll-snap-align: start; /* Snap sections to start when scrolling */
  flex: 1; /* This ensures the section grows to fill the space, pushing the footer down */
}

.title {
  font-size: 10rem;
  font-weight: bold;
  margin-bottom: 10px;
  animation: fadeIn 1.7s ease-out;
  color: rgb(18 6 82);
}

.mediumtitle {
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 10px;
  animation: fadeIn 1.7s ease-out;
  color: rgb(18 6 82);
}

.subtitle {
  font-size: 2rem;
  margin-bottom: 20px;
  animation: fadeIn 1.8s ease-out;
}

.description, h2, p {
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto;
  animation: fadeIn 1.9s ease-out;
}

h2 {
  margin-bottom: 15px;
  font-size: 2rem;
  color: rgb(18 6 82);

}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Mobile Styles */
@media (max-width: 768px) {
  .title {
    font-size: 6rem;
    margin-top: 30px;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .description, h2, p {
    font-size: 1rem;
  }

  .feature-grid, .interface-showcase {
    display: block;
    margin: 0 auto;
  }

  .feature-grid-rectangle, .showcase-item {
    margin-bottom: 20px;
  }

  .section {
    height: auto; /* Adjust height for mobile */
    padding: 20px; /* Reduce padding on mobile */
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .navbar-links {
    display: none;
  }

  
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #f0f0f5; /* Very light grayish blue */
  color: #333; /* Dark gray text */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.navbar-logo a {
  color: #333;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-links a {
  color: #333;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.navbar-links a {
  display: inline-block; /* Ensure padding and transform work correctly */
  text-decoration: none;
  padding: 10px 15px; /* Adjust padding as needed */
}

.navbar-links a:hover {
  background-color: #e1e1e6; /* Light grayish blue */
  border-radius: 4px;
}

/* Footer Styles */
.footer {
  color: #333; /* Dark gray text */
  padding: 40px;
  text-align: left;
  font-size: 14px;
  position: relative;
  width: 100%;
  bottom: 0;
}

.footer-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.footer h4 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  margin-bottom: 5px;
}

.footer ul li a {
  color: #333;
  text-decoration: none;
}

.footer ul li a:hover {
  text-decoration: underline;
}

.footer p {
  text-align: center;
  margin-top: 20px;
}

.social-icons {
  margin-bottom: 30px;
  text-align: center;
}

.social-icons a {
  margin: 0 10px;
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

/* Apply gradients to sections for smoother transitions */
#section1 {
  background: linear-gradient(
    to bottom,
    #f0f0f5, /* Very light grayish blue */
    #e1e1e6 100% /* Transition to light grayish blue */
  );
}

#section2 {
  background: linear-gradient(
    to bottom,
    #e1e1e6, /* Light grayish blue */
    #d2d2d8 100% /* Transition to grayish blue */
  );
}

#section3 {
  background: linear-gradient(
    to bottom,
    #d2d2d8, /* Grayish blue */
    #c3c3cc 100% /* Transition to slightly darker grayish blue */
  );
}

#section4 {
  background: linear-gradient(
    to bottom,
    #c3c3cc, /* Slightly darker grayish blue */
    #b4b4c0 100% /* Transition to dark grayish blue */
  );
}

#section5 {
  background: linear-gradient(
    to bottom,
    #b4b4c0, /* Dark grayish blue */
    #a5a5b0 100% /* Transition to nearly white */
  );
}
#section6 {
  background: linear-gradient(
    to bottom,
    #a5a5b0, /* Dark grayish blue */
    #94949e 100% /* Transition to nearly white */
  );
}


.device-table {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

td {
  padding: 20px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  border-radius: 12px;
  cursor: pointer;
}

td:hover {
  background-color: #f9f9f9; /* Very light gray */
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  color: #333;
}

.icon-size {
  width: 80px;
  height: 80px;
  transition: transform 0.3s ease;
  fill: #333; /* Dark gray */
}

td:hover .icon-size {
  transform: scale(1.1);
  fill: #666; /* Medium gray */
}

td span {
  display: block;
  margin: 10px 0;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.sajat {
  color: #333;
  text-decoration: none; /* no underline */
}


.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  grid-template-rows: repeat(3, 1fr); /* 3 rows */
  gap: 20px; /* Spacing between rectangles */
}

.feature-grid-rectangle {
  background-color: #f0f0f0; /* Light background color */
  border-radius: 10px; /* Rounded corners */
  padding: 20px;

  font-size: 18px;
  font-weight: bold;
}

.user-friendly-interface {
  background-color: #ffffff; /* Clean white background for contrast */
  padding: 60px 20px;
  text-align: center;
}


.user-friendly-interface p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
}

.interface-showcase {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.showcase-item {
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  padding: 20px;
  max-width: 280px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.showcase-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0,0,0,0.2);
}

.showcase-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.showcase-item h3 {
  font-size: 1.6rem;
  color: #333;
  margin: 10px 0;
}

.showcase-item p {
  font-size: 1rem;
  color: #555;
}
.gif {
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.feature-text {
  color: #535353
}
.feature-text-desc {
  color: #777676
}
/* Mobile Styles */
@media (max-width: 768px) {
  .navbar-links {
    display: none; /* Hide links on small screens */
  }

  .title {
    font-size: 6rem;
    font-weight: bold;
    margin-bottom: 10px;
    animation: fadeIn 1.7s ease-out;
    color: rgb(18 6 82);
  }

  .feature-grid, .interface-showcase {
    display: block;
    margin: 0 auto;
  }

  .feature-grid-rectangle, .showcase-item {
    margin-bottom: 20px;
  }

.device-table {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

td {
  padding: 20px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  border-radius: 12px;
  cursor: pointer;
}

td:hover {
  background-color: #f9f9f9; /* Very light gray */
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  color: #333;
}

.icon-size {
  width: 60px;
  height: 0px;
  transition: transform 0.3s ease;
  fill: #333; /* Dark gray */
}

td:hover .icon-size {
  transform: scale(1.1);
  fill: #666; /* Medium gray */
}

td span {
  display: block;
  margin: 10px 0;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.sajat {
  color: #333;
  text-decoration: none; /* no underline */
}

}
